//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      matnr: "",
      name: "",
      season: "",
      image: "",
      kunnr: "",
      kunnrName: "",
      tableData: {
        column: [
          { label: "网格值", prop: "gridValue" },
          { label: "库存", prop: "stock" },
          // {label: '销量', prop: 'salesVolume'}
        ],
        data: [],
      },
      matnrList:[],
      showGoodsSwitch:false,
      totalTableData: {
        column: [
          { label: "网格值", prop: "gridValue" },
          { label: "库存", prop: "stock" },
          // {label: '销量', prop: 'salesVolume'}
        ],
        data: [],
      },
      items: [],
      tableHeader: "",
      totalItems: [],
      active: 0,
      totalShop: "",
      totalTotal: 0,
      activeName: "",
      currentSize: null,
    };
  },
  created() {
    this.matnr = this.$route.query.code
    this.getData();
    this.currentSize = this.$route.query.code.slice(-2);
  },
  methods: {
    getData() {
      this.$toast.loading({
        message: "查询中",
        forbidClick: true,
        duration: 0,
      });
      this.axios
        .get(
          "/" +
            this.$ajaxPrefix.consumer +
            // "/api/v1/wx/cp/storestock/getStockQuery?matnr=" +
            "/v1/wx/cp/storestock/getStockQuery?matnr=" +
            this.$route.query.code +
            "&kunnr=" +
            this.$route.query.shop
        )
        .then((res) => {
          this.$toast.clear();
          console.log(res);
          if (res.data.code == 0) {
            this.showDetail(res.data.data);
          } else {
            // this.$toast({ message: "没有查到库存信息", duration: 2000 });
          }
          this.getMatnrData()
        });
    },
    getMatnrData() {
      let shop = this.$route.query.shop;
      let totalShop = shop.slice(0, shop.length - 4);
      // this.$toast.loading({
      //   message: "查询中",
      //   forbidClick: true,
      //   duration: 0,
      // });

      this.totalShop = totalShop;
      this.axios
        .get(
          "/" +
            this.$ajaxPrefix.consumer +
            // "/api/v1/wx/cp/storestock/getStockQuery?matnr=" +
            // "/v1/wx/cp/storestock/getStockQueryByAdccu?matnr=" +
            "/v1/wx/cp/storestock/toSatnrAndSize?matnr=" +
            this.$route.query.code +
            "&adccu=" +
            totalShop
        )
        .then((res) => {
          this.$toast.clear();
          console.log(res,'toSatnrAndSize');
           //多商品添加商品选择
           if (res.data.code == 0 && res.data.data) {
              this.matnrList = res.data.data
              if(res.data.data.length > 1){
                  this.showGoodsDialog()
              }else if(res.data.data.length ==1){
                this.matnr = res.data.data[0].matnr
                this.maktx = res.data.data[0].maktx
                this.getTotalData() 
              }else{
                alert('没有查询到相关产品')
              } 
           }
        });
    },
     //商品选择弹窗
        showGoodsDialog(){
            this.showGoodsSwitch = true
        },
        closeGoodsDialog(e){
            //第一次进入取消时继续请求
            if (!this.matnr){
                this.getData()
            }
        },
        changeGoods(goods){
            this.showGoodsSwitch = false
            this.matnr = goods.matnr
            this.maktx = goods.maktx
            this.getTotalData() 
        },
    getTotalData() {
      let shop = this.$route.query.shop;
      let totalShop = shop.slice(0, shop.length - 4);
      // this.$toast.loading({
      //   message: "查询中",
      //   forbidClick: true,
      //   duration: 0,
      // });

      this.totalShop = totalShop;
      this.axios
        .get(
          "/" +
            this.$ajaxPrefix.consumer +
            // "/api/v1/wx/cp/storestock/getStockQuery?matnr=" +
            // "/v1/wx/cp/storestock/getStockQueryByAdccu?matnr=" +
            "/v1/wx/cp/storestock/getStockQueryByS4Adccu?matnr=" +
            this.matnr +
            "&adccu=" +
            totalShop
        )
        .then((res) => {
          this.$toast.clear();
          console.log(res);
          if (res.data.code == 0) {
            this.showTotalDetail(res.data.data, shop);
          } else {
            this.$toast({ message: "没有查到库存信息", duration: 2000 });
          }
        });
    },
    showDetail(products) {
      console.log(products);
      if (products && products.length > 0) {
        let dict = {};
        for (let product of products) {
          let kunnr = product.kunnr;
          let items = dict[kunnr];
          if (!items) {
            items = [];
          }
          items.push({
            gridValue: product.j3asize,
            stock: Number(product.stock).toFixed(0),
          });
          dict[kunnr] = items;
        }
        for (let key in dict) {
          let products = dict[key];
          let total = products.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.stock);
          }, 0);
          this.items.push({ kunnr: key, total: total, products: products });
        }
        console.log(this.items);
        let item = products[0];
        this.matnr = item.matnr;
        this.maktx = item.maktx;
        this.kunnr = item.kunnr;
        this.kunnrName = item.name1;
        let season;
        switch (item.sseas) {
          case "X":
            season = "春夏";
            break;
          case "Y":
            season = "秋冬";
            break;
          default:
            season = "四季";
        }
        this.season = item.syear + season;

        let reg = /[A-Z]+[0-9]+/;
        let result = this.matnr.match(reg);
        if (result) console.log(result);
        setTimeout(() => {
          this.image = `https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com/stockcheck/${products[0]["image"]}`;
        }, 200);

        console.log(this.image);
        let array = [];
        let total = 0;
        for (let item of products) {
          total += Number(item.stock);
          array.push({
            gridValue: item.j3asize,
            stock: Number(item.stock).toFixed(0),
          });
        }
        if (total > 0) {
          array.push({ gridValue: "总计", stock: total + "" });
        }
        this.tableData.data = array;
      } else {
        // this.$toast({ message: "没有查到库存信息", duration: 2000 });
      }
    },
    showTotalDetail(products) {
      if (products && products.length > 0) {
        let objectWrap = {};
        let shopObj = {};
        for (let i = 0; i < products.length; i++) {
          if (this.tableHeader == "") {
            this.tableHeader = products[i].j3asize;
          }
          if (!objectWrap[products[i].j3asize]) {
            objectWrap[products[i].j3asize] = {};
            objectWrap[products[i].j3asize]["总店"] = 0;
          }
          shopObj[products[i].kunnr] = 0;
          objectWrap[products[i].j3asize][products[i].kunnr] = Number(
            products[i].stock
          );
        }
        for (var size in objectWrap) {
          let sizeTotal = 0;
          for (var store in shopObj) {
            if (!objectWrap[size][store]) {
              objectWrap[size][store] = 0;
            }
          }
          for (var store in objectWrap[size]) {
            sizeTotal += objectWrap[size][store];
          }

          objectWrap[size]["总店"] = sizeTotal;
        }
        this.totalItems = objectWrap;
        for (var eyitem in objectWrap) {
          var sizeObj = objectWrap[eyitem];
          var newData = {};
          Object.keys(sizeObj)
            .sort(function(a,b){
              console.log(a.localeCompare(b))
              return a.localeCompare(b)
            })
            .map((key) => {
              newData[key] = sizeObj[key];
            });
          this.totalItems[eyitem]=newData;
        }
        console.log("tableHeader", this.tableHeader);
        console.log("objectWrap", JSON.stringify(objectWrap[this.tableHeader]));
        console.log("shopObj", JSON.stringify(this.totalItems));
        // let sizeSet = new Set();
        // products.forEach((item) => {
        //   sizeSet.add(item.j3asize);
        // });
        // let sizeArr = Array.from(sizeSet);
        // sizeArr.sort((a, b) => {
        //   return Number(a) - Number(b);
        // });
        // let dict = {};
        // console.log('products',products);
        // for (let product of products) {
        //   let kunnr = product.kunnr;
        //   let items = dict[kunnr];
        //   if (!items) {
        //     items = [];
        //   }
        //   items.push({ gridValue: product.j3asize, stock: Number(product.stock).toFixed(0) });
        //   dict[kunnr] = items;
        // }

        // for (let key in dict) {
        //   let products = dict[key];
        //   let temp_ = [];
        //   sizeArr.forEach((item_) => {
        //     let isExist = false;
        //     let existItem = {};

        //     for (let i = 0; i < products.length; i++) {
        //       let item__ = products[i];

        //       if (item_ == item__.gridValue) {
        //         isExist = true;
        //         existItem = item__;
        //         break;
        //       }
        //     }
        //     if (!isExist) {
        //       temp_.push({ gridValue: item_, stock: "0" });
        //     } else {
        //       temp_.push(existItem);
        //     }
        //   });

        //   products = temp_;

        //   let total = products.reduce((accumulator, currentValue) => {
        //     return accumulator + Number(currentValue.stock);
        //   }, 0);
        //   products.sort((a, b) => {
        //     return Number(a.gridValue) - Number(b.gridValue);
        //   });
        //   this.totalItems.push({
        //     kunnr: key,
        //     total: total,
        //     products: products,
        //   });
        // }

        // let zongData = {};
        // sizeArr.forEach((item, index) => {
        //   zongData[item] = 0;
        //   this.totalItems.forEach((item__) => {
        //     zongData[item] += Number(item__.products[index].stock);
        //   });
        // });

        // console.log(zongData);

        // let zondian = {
        //   kunnr: "总店",
        //   products: [],
        // };
        // for (let key in zongData) {
        //   zondian.products.push({
        //     gridValue: key,
        //     stock: Number(zongData[key]),
        //   });
        // }
        // this.totalItems.unshift(zondian);

        // console.log(this.totalItems);
        // for(let i=0; i<this.totalItems.length; i++){
        //   this.totalItems[i].products.sort(function(a, b){
        //     return Number(b.gridValue) - Number(a.gridValue);
        //   });
        // }

        // let array = [];
        // let total = 0;
        // for (let item of products) {
        //   total += Number(item.stock);
        //   array.push({ gridValue: item.j3asize, stock: item.stock });
        // }
        // if (total > 0) {
        //   array.push({ gridValue: "总计", stock: total + "" });
        // }
        // this.totalTableData.data = array;
      } else {
        this.$toast({ message: "没有查到总店库存信息", duration: 2000 });
      }
    },
  },
  mounted() {
    document.title = "库存结果";
  },
};
